import React, { Component } from "react";
import Carousel from "react-spring-3d-carousel";
import { v4 as uuidv4 } from "uuid";
import { config } from "react-spring";
import img1 from "./Carousel/0.png";
import img2 from "./Carousel/1.png";
import img3 from "./Carousel/2.png";
import img4 from "./Carousel/3.png";

import "./Section3Carousel.css";
import MediaQuery from "react-responsive";
import { Box, Paper, Grid, Button } from "@mui/material";
export default class DCarousel extends Component {
  state = {
    goToSlide: 0,
    offsetRadius: 3,
    showNavigation: true,
    config: config.molasses,
  };

  slides = [
    {
      key: uuidv4(),
      content: <img className="flip-card" src={img1} alt="1" />,
    },
    {
      key: uuidv4(),
      content: <img className="flip-card" src={img2} alt="2" />,
    },
    {
      key: uuidv4(),
      content: <img className="flip-card" src={img3} alt="3" />,
    },
    {
      key: uuidv4(),
      content: <img className="flip-card" src={img4} alt="4" />,
    },
    {
      key: uuidv4(),
      content: <img className="flip-card" src={img1} alt="1" />,
    },
    {
      key: uuidv4(),
      content: <img className="flip-card" src={img2} alt="2" />,
    },
    {
      key: uuidv4(),
      content: <img className="flip-card" src={img3} alt="3" />,
    },
    {
      key: uuidv4(),
      content: <img className="flip-card" src={img4} alt="4" />,
    },
  ].map((slide, index) => {
    return { ...slide, onClick: () => this.setState({ goToSlide: index }) };
  });

  onChangeInput = (e) => {
    this.setState({
      [e.target.name]: parseInt(e.target.value, 10) || 0,
    });
  };

  render() {
    return (
      <>
        <div id="gallery" className="part3__bg">
          <div>
            <h1 className="artGallery">Sneakpeeks</h1>
          </div>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={2} md={2}></Grid>
              <Grid item xs={8} md={8}>
                <MediaQuery minWidth={1200}>
                  <div
                    className="carousel__body"
                    style={{
                      marginBottom: "10rem",
                      marginTop: "4rem",
                      width: "100%",
                      height: "30rem",
                      position: "relative",
                      left: 0,
                      right: 0,
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Carousel
                      slides={this.slides}
                      goToSlide={this.state.goToSlide}
                      offsetRadius={this.state.offsetRadius}
                      showNavigation={true}
                      goToSlideDelay={200}
                      animationConfig={this.state.config}
                    />
                  </div>{" "}
                </MediaQuery>
                <MediaQuery minWidth={750} maxWidth={1200}>
                  <div
                    className="carousel__body"
                    style={{
                      width: "100%",
                      marginTop: "4rem",
                      marginBottom: "4rem",
                      height: "25rem",
                      position: "relative",
                      left: 0,
                      right: 0,
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Carousel
                      slides={this.slides}
                      goToSlide={this.state.goToSlide}
                      offsetRadius={this.state.offsetRadius}
                      showNavigation={false}
                      goToSlideDelay={200}
                      animationConfig={this.state.config}
                    />
                  </div>
                </MediaQuery>
                <MediaQuery maxWidth={750}>
                  <div
                    className="carousel__body"
                    style={{
                      width: "100%",
                      height: "10rem",
                      marginBottom: "4rem",
                      position: "relative",
                      left: 0,
                      right: 0,
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <Carousel
                      slides={this.slides}
                      goToSlide={this.state.goToSlide}
                      offsetRadius={this.state.offsetRadius}
                      showNavigation={false}
                      goToSlideDelay={200}
                      animationConfig={this.state.config}
                    />
                  </div>
                </MediaQuery>
              </Grid>
              <Grid item xs={12} md={2}></Grid>
            </Grid>
          </Box>
        </div>
      </>
    );
  }
}
