import React from "react";
import styled from "styled-components";

function Section2() {
  return (
    <Container>
      <Wrap>
        <SubWrapper>
          <Left>
            <Title>About Atlaria</Title>
            <Description>
              <p>
                Atlaria is an NFT project starting with a collection of unique,
                hand-drawn sea critters living on the Solana blockchain.
              </p>

              <p>
                This project isn't just about collectibles. We plan to
                collaborate with ecological organizations to create a bigger
                change on improving our seas. With all these ideas, we would
                love to hear from YOU, we are excited to involve our
                participant's ideas in our project and hear from the community!
              </p>

              <p>
                Everyone is welcome to join us on this journey no matter if
                you’re a veteran NFT trader or you’re just getting into NFTs. We
                strive to be the most fun community in the Solana NFT ecosystem
                and would love for you to be a part of it. You can hit us up on
                Discord, Twitter, and our other channels to get our latest
                updates.
              </p>
            </Description>
          </Left>
          <Right>
            <img src="assests/Altaria.gif" alt="" />
          </Right>
        </SubWrapper>
      </Wrap>
    </Container>
  );
}

export default Section2;

const Container = styled.section`
  min-height: 10vh;
  background: #fff;
  // min-width: 100vw;
  padding-top: 80px;
  @media (max-width: 800px) {
    padding-top: 0px;
  }
`;

const Wrap = styled.div`
  margin: 64px 64px;
  display: flex;
  justify-content: center;
  @media (max-width: 800px) {
    margin: 64px 32px;
  }
`;

const SubWrapper = styled.div`
  max-width: 1100px;
  width: 100%;
  min-width: 200px;
  display: flex;
  justify-content: space-evenly;
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Left = styled.div``;

const Title = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  color: #0669b2;
  @media (max-width: 1100px) {
    font-size: 36px;
  }
`;

const Description = styled.div`
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 27px;
  letter-spacing: 0em;

  text-align: justified;
  color: #000;
  max-width: 610px;
  width: 100%;
  min-width: 200px;
  @media (max-width: 1100px) {
    font-size: 18px;
  }
`;

const Right = styled.div`
  img {
    width: 410px;
    height: 410px;
  }
  @media (max-width: 1100px) {
    img {
      max-width: 410px;
      max-height: 410px;
      width: 100%;
      height: auto;
      min-width: 240px;
      min-height: 240px;
    }
  }
  @media (max-width: 800px) {
    margin-top: 64px;
  }
`;
