import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import Rock from "../../invaders/Rock 1.png";
import Ice from "../../invaders/Ice 1.png";
import Lava from "../../invaders/Lava 1.png";
import Midnight from "../../invaders/Midnight 1.png";
import Moon from "../../invaders/Moon 1.png";
import Ocean from "../../invaders/Ocean 1.png";
import Star from "../../invaders/Star 1.png";

const images = [Rock, Lava, Star, Ocean, Midnight, Moon, Ice];

function Section3() {
  const settings = {
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: 0,
    mobileFirst: true,
    beforeChange: (current, next) => setImageIndex(next),
  };

  const [imageIndex, setImageIndex] = React.useState(0);

  return (
    <Container>
      <Wrap>
        <CarouselWrap>
          <Slider {...settings}>
            {images.map((img, index) => (
              <div
                className={
                  index === imageIndex
                    ? "slide activeSlide"
                    : index < imageIndex
                    ? "slide slideLeft"
                    : "slide slideRight"
                }
              >
                <img src={img} alt={img} />
              </div>
            ))}
          </Slider>
          <Progress>
            {images.map((img, index) => (
              <div
                className={
                  index === imageIndex ? "progressbar Active" : "progressbar"
                }
              />
            ))}
          </Progress>
        </CarouselWrap>
      </Wrap>
    </Container>
  );
}

export default Section3;

const Container = styled.div`
  background: #fff;
`;

const Wrap = styled.div`
  margin: 64px 45px;
`;

const Title = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  text-align: center;
  color: #fa8fd2;
`;

const CarouselWrap = styled.div`
  margin: 64px 0;

  .slide img {
    width: 450px;
    height: 450px;
    margin: 0 0;
  }

  .slide {
    transform: scale(0.8);
    transition: transform 300ms;
  }

  .slideLeft {
    transform: scale(0.7);
    // transform: translateX(60%);
    // transition: transform 300ms;
    // z-index: 1;
  }

  .slideRight {
    transform: scale(0.7);
    // z-index: 1;
    // transform: translateX(-60%);
    // transition: transform 300ms;
  }

  .activeSlide {
    transform: scale(1.1);
    opacity: 1;
    z-index: 100;
  }
`;

const Progress = styled.div`
    display: flex;
    justify-content: center;
    .progressbar{
        margin: 64px 12px;
        margin-bottom: 0px;
        width: 32px;
        height: 0px;
        border: 5px solid #FFFFFF66;
        transition: border: 300ms;
    }
    .Active {
        margin: 64px 12px;
        margin-bottom: 0px;
        width: 32px;
        height: 0px;
        border: 5px solid #FFFFFF;
        transition: border: 300ms;
    }
`;
